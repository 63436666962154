<template>
  <router-view />
    <Header></Header>
    <Fotter></Fotter>
</template>

<script>
import Header from './components/Header.vue'
import Fotter from './components/Fotter.vue'

export default {
  name: 'App',
  components: {
    Header,
    Fotter
  }
}
</script>

<style>
body {
  margin: 0;
  padding-top: 60px;
}
#app {
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Helvetica, Arial, sans-serif;
  text-align: center;
  color: #363636
}
h1 {
    margin-block-start: 0 ;
    margin-block-end: 0;
}
ul {
  list-style-type: none;
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding: 0;
}
figure {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
button {
  padding: 0;
  border-width: 0;
  background-color: white;
}
img {
  width: 100%;
}
a {
  text-decoration: none;
}
p {
  letter-spacing: 2px;
  line-height: 1.7;
  margin-block-start: 0;
  margin-block-end: 0;
}
dl {
    margin-block-start: 0;
    margin-block-end: 0;
}
dd {
  margin-inline-start: 0;
}
.anchor {
    display: block;
    padding-top: 60px;
    margin-top: -60px;
}
.btn-wrap {
  padding: 20px;
}
.btn-primary {
  color: #fff;
  letter-spacing: 2px;
  /*width: 100%;*/
  max-width: 340px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 30px;
  margin: 1rem auto;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;
  background: rgb(84,170,249);
  background: linear-gradient(150deg, rgba(84,170,249,1) 0%, rgba(155,84,249,1) 40%, rgba(197,93,253,1) 60%, rgba(252,83,184,1) 100%);
}
.btn-primary__txt:link,
.btn-primary__txt:visited {
  color: #fff;
}
.background-grad{
  background: rgb(84,170,249);
  background: linear-gradient(90deg, rgba(84,170,249,1) 0%, rgba(155,84,249,1) 40%, rgba(197,93,253,1) 60%, rgba(252,83,184,1) 100%);
}
.background-grad-base {
  background-color: #ffffffb0;
  padding: 20px 20px 60px;
}
.mb-1r{
  margin-bottom: 1rem;
}
.width-100p {
  width: 100%;
}
@media screen and (max-width:600px) {
}
@media screen and (min-width:769px) {
  .pc-none {
  display: none;
}
}
</style>
