<template>
  <div class="container">
    <Navbar />
    <SubscriptionStatus />
    <p v-if="isLoggedIn" class="btn-primary">
        <router-link to="/divination-three" class="btn-primary__txt">占いを始める</router-link>
    </p>
  </div>
</template>

<script>
import Navbar from '../components/Navbar'
import SubscriptionStatus from '../components/SubscriptionStatus'

export default {
  components: { Navbar,SubscriptionStatus },

  data () {
        return {
            email: window.localStorage.getItem('uid'),
        }
  },

  computed: {
        isLoggedIn() {
            return Boolean(this.email)
        }
    },
}
</script>

