<template>
  <div>
    <h1>Subscription Status</h1>
    <p v-if="role === 'premium'">You are a premium user! 🎉</p>
    <p v-else>You are using a standard account.</p>

    <button v-if="role !== 'premium'" @click="subscribeToPremium">Subscribe to Premium</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: 'standard', // 初期は標準ユーザー
    };
  },
  mounted() {
    this.fetchUserRole();
  },
  methods: {
    async fetchUserRole() {
      try {
        const response = await this.$axios.get('/api/user');
        this.role = response.data.role;
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    },
    async subscribeToPremium() {
      try {
        const response = await this.$axios.post('/api/subscriptions', { plan_id: 'PREMIUM_PLAN_ID' });
        if (response.data.success) {
          this.role = 'premium'; // サブスクリプション成功後に権限を更新
        }
      } catch (error) {
        console.error('Error subscribing to premium:', error);
      }
    }
  }
};
</script>