<template>
  <div>
    <div class="btn-primary">
        <a target="_blank" data-url="https://square.link/u/XHarYQDl?src=embd" href="https://square.link/u/XHarYQDl?src=embed">Premiumに登録する</a>
    </div>
  </div>
</template>

  <!--<script>
    function showCheckoutWindow(e) {
      e.preventDefault();

      const url = document.getElementById('embedded-checkout-modal-checkout-button').getAttribute('data-url');
      const title = 'Square Online Checkout';

      // Some platforms embed in an iframe, so we want to top window to calculate sizes correctly
      const topWindow = window.top ? window.top : window;

      // Fixes dual-screen position                                Most browsers          Firefox
      const dualScreenLeft = topWindow.screenLeft !==  undefined ? topWindow.screenLeft : topWindow.screenX;
      const dualScreenTop = topWindow.screenTop !==  undefined   ? topWindow.screenTop  : topWindow.screenY;

      const width = topWindow.innerWidth ? topWindow.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
      const height = topWindow.innerHeight ? topWindow.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

      const h = height * .75;
      const w = 500;

      const systemZoom = width / topWindow.screen.availWidth;
      const left = (width - w) / 2 / systemZoom + dualScreenLeft;
      const top = (height - h) / 2 / systemZoom + dualScreenTop;
      const newWindow = window.open(url, title, `scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`);

      if (window.focus) newWindow.focus();
    }

    // This overrides the default checkout button click handler to show the embed modal
    // instead of opening a new tab with the given link url
    document.getElementById('embedded-checkout-modal-checkout-button').addEventListener('click', function (e) {
      showCheckoutWindow(e);
    });
  </script>-->
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h1 {
    margin-block-start: 0 !important;
  }
  h3 {
    margin: 40px 0 0;
  }
  a {
  color: #363636;
  text-decoration: none;
  }
  button {
  padding: 0;
  border-width: 0;
  background-color: white;
}
  .hello {
    margin: 0 auto ;
    position: relative;
    z-index: 10;
  }
  .hello-logo {
    max-width: 240px;
    margin: 40px 10px;
  }
  .key-visual {
    margin: 0 10px;
  }
  .key-visual__imgarea {
    display: flex;
    justify-content: center;
    margin: 0 10px 60px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  }
  .key-visual__imgarea__item {
    width: 31%;
    margin: 0 1.5%;
    max-width: 150px;
  }
  .key-visual__imgarea__item:nth-of-type(2){
    position: relative;
    bottom: -30px;
  }
  .key-copy {
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 2;
    margin-bottom: 24px;
  }
  @media screen and (min-width:769px) {
    .key-visual__imgarea {
      max-width: 600px;
      margin: 0 auto 60px;
    }
  }
  .btn-subscription{
    overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 259px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: -2px 10px 5px rgba(0, 0, 0, 0);
  border-radius: 10px;
  font-family: SQ Market, SQ Market, Helvetica, Arial, sans-serif;
  }
  .btn-subscription a {
    display: inline-block;
    font-size: 18px;
    line-height: 48px;
    height: 48px;
    color: #ffffff;
    min-width: 212px;
    background-color: #006aff;
    text-align: center;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1) inset;
    border-radius: 6px;
  }
  </style>
  