<template>
  <div>
      <h2>アカウントを登録</h2>
      <form @submit.prevent="signUp">
          <input type="text" required placeholder="ユーザー名（半角英数）" v-model="registration.name">
          <input type="email" required placeholder="メールアドレス" v-model="registration.email">
          <input type="password" required placeholder="パスワード（半角英数/6文字以上）" v-model="registration.password">
          <input type="password" required placeholder="パスワード（確認用）" v-model="registration.password_confirmation">
          <div class="submit-area">
            <div class="error">{{ error }}</div>
            <button class="btn-primary"><span class="btn-primary__txt">会員登録する（無料）</span></button>
          </div>
      </form>
  </div>
</template>

<script>
import axios from 'axios'
import setItem from '../auth/setItem'

export default {
  //emits: ['redirectToPayment'],  // 支払いページへのリダイレクト用イベント
  //emits: ['redirectToPayment'],
  data () {
  return {
    registration: {
      name: '',
      email: '',
      password: '',
      password_confirmation: ''
    },
    error: null  // errorの初期化
  }
},

methods: {
  async signUp() {
    this.error = null;
    try {
      // 環境変数からAPIのベースURLを取得
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

      // エンドポイントを環境変数から設定
      const res = await axios.post(`${apiBaseUrl}/auth/`, {
        registration: this.registration,
      },
      {
        withCredentials: true, // 認証情報を含める設定
      });

      if (!res) {
        throw new Error('アカウントを登録できませんでした');
      }

      // 正常にレスポンスが返ってきた場合、認証情報を保存
      setItem(res.headers, res.data.data.name);

      // サインアップが成功したら支払いページへリダイレクト
      this.$router.push({ name: 'UserHome' });
    } catch (error) {
      console.info(error);

      if (error.response) {
        this.error = error.response.data.message || 'アカウントを登録できませんでした';
      } else if (error.request) {
        this.error = 'サーバーから応答がありませんでした';
      } else {
        this.error = '不明なエラーが発生しました';
      }
    }
  },
}


}
</script>
