<template>
    <div class="container welcome">
      <div v-if="shouldShowLoginForm">
        <LoginForm @redirectToChatRoom="redirectToChatRoom" />
        <p class="change-form">初めての方は<span @click="shouldShowLoginForm = false">こちら</span></p>
      </div>
      <div v-if="!shouldShowLoginForm">
        <SignupForm @redirectToChatRoom2="redirectToChatRoom2" />
        <p class="change-form">アカウントをお持ちの方は<span @click="shouldShowLoginForm = true">こちら</span></p>
      </div>
    </div>
</template>
  
<script>
import LoginForm from '../components/LoginForm.vue'
import SignupForm from '../components/SignupForm.vue'
export default {
  components: { LoginForm, SignupForm },
  data () {
    return {
      shouldShowLoginForm: false
    }
  },
  methods: {
    redirectToChatRoom () {
      //this.$router.push({ name: 'DivinationThree' })
      this.$router.push({ name: 'UserSubscription' })
    },
    redirectToChatRoom2 () {
      //this.$router.push({ name: 'DivinationThree' })
      this.$router.push({ name: 'UserSubscription' })
    },
  }
}

</script>
  
<style>
  .welcome {
      text-align: center;
      padding: 40px 0;
    }
  .welcome form {
    width: 300px;
    margin: 20px auto 0;
  }
  .welcome label {
    display: block;
    margin: 20px 0 10px;
  }
  .welcome input {
    background: #f4f4f4;
    width: 100%;
    padding: 12px 20px;
    margin: 8px auto;
    border-radius: 4px;
    border: 1px solid #eee;
    outline: none;
    box-sizing: border-box;
    letter-spacing: 2px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Helvetica, Arial, sans-serif;
  }
  .change-form {
    font-size: 14px;
    margin: 10px;
  }
  .change-form span {
    color: #AE50F8;
    text-decoration: underline;
  }
  .change-form span:link,
  .change-form span:visited {
    color: #AE50F8;
  }
  h2 {
    font-size: 18px;
    letter-spacing: 2px;
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Helvetica, Arial, sans-serif;
  }

  form .submit-area {
    margin: 16px 0;
  }
  form .submit-area .message{
    padding-bottom: 8px;
    color: #AE50F8;
    font-weight: bold;
  }
</style>