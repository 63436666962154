<template>
    <div class="hello background-grad">
      <div class="background-grad-base">
      <div class="key-visual">
        <h1><img class="hello-logo" alt="uranao logo" src="../assets/logo.svg"></h1>
        <ul class="key-visual__imgarea">
          <li class="key-visual__imgarea__item"><img src="../assets/tarotcard_10.png"></li>
          <li class="key-visual__imgarea__item"><img src="../assets/tarotcard_01.png"></li>
          <li class="key-visual__imgarea__item"><img src="../assets/tarotcard_21.png"></li>
        </ul>
      </div>
      <p class="key-copy">
        誰でも、自由に、もっと簡単に<br>
        「ジブン占い」始めよう。
      </p>
      <p class="btn-primary">
        <router-link to="/divination" class="btn-primary__txt">毎日占い(無料)</router-link>
      </p>
      <p class="btn-primary">
        <router-link to="/divination-three" class="btn-primary__txt">過去・現在・未来占い（無料）</router-link>
      </p>
    </div>
  </div>
  </template>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h1 {
    margin-block-start: 0 !important;
  }
  h3 {
    margin: 40px 0 0;
  }
  a {
  color: #363636;
  text-decoration: none;
  }
  button {
  padding: 0;
  border-width: 0;
  background-color: white;
}
  .hello {
    margin: 0 auto ;
    position: relative;
    z-index: 10;
  }
  .hello-logo {
    max-width: 240px;
    margin: 40px 10px;
  }
  .key-visual {
    margin: 0 10px;
  }
  .key-visual__imgarea {
    display: flex;
    justify-content: center;
    margin: 0 10px 60px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  }
  .key-visual__imgarea__item {
    width: 31%;
    margin: 0 1.5%;
    max-width: 150px;
  }
  .key-visual__imgarea__item:nth-of-type(2){
    position: relative;
    bottom: -30px;
  }
  .key-copy {
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 2;
    margin-bottom: 24px;
  }
  @media screen and (min-width:769px) {
    .key-visual__imgarea {
      max-width: 600px;
      margin: 0 auto 60px;
    }
  }
  </style>
  