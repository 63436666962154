import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import './assets/main.css'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
//import VueGtm from 'vue-gtm';
import { installGoogleAnalytics } from './plugins/gtag';


library.add(faHeart)

const app = createApp(App);

// Google Analyticsの設定
installGoogleAnalytics(app);

//app.use(VueGtm, {
//  id: 'GTM-5VDCS27D', // GoogleタグマネージャーのIDをここに設定（例: GTM-XXXXXX）
//  vueRouter: router, // オプション: Vue Routerを使用している場合、ルーターを指定すると自動でページビューを追跡
//  enabled: true, // 環境に応じて有効/無効を切り替えることが可能
//  debug: true, // デバッグモード（trueにするとコンソールに詳細が表示される）
//});


  
createApp(App).use(router).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
