<template>
    <div class="container">
        <Navbar />
        <div class="menu-area">
            <h2>占いメニュー</h2>
            <p v-if="isLoggedIn" class="btn-primary">
                <router-link to="/divination" class="btn-primary__txt">毎日占い</router-link>
            </p>
            <p v-if="isLoggedIn" class="btn-primary">
                <router-link to="/divination-three" class="btn-primary__txt">過去・現在・未来占い（会員限定）</router-link>
            </p>
        </div>
    </div>
</template>

<script>
import Navbar from '../components/Navbar'

export default {
  components: { Navbar },

  data () {
        return {
            email: window.localStorage.getItem('uid'),
        }
  },

  computed: {
        isLoggedIn() {
            return Boolean(this.email)
        }
    },
}
</script>

<style scoped>
.menu-area {
    padding: 24px;
}
</style>