function gtag() {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(arguments);
  }
}

export const installGoogleAnalytics = () => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', 'G-F6NCQWNRKD');
  }
};