<template>
    <nav>
        <!--<p v-if="isLoggedIn" class="plan">{{ plan }}</p>-->
        <div class="user-area">
            <img v-if="isLoggedIn" v-bind:src="imgsUrl" alt="user">
            <div class="user-area__inner">
                <p><span class="name">{{ name }}</span></p>
                <p class="email"> {{ email }} </p>
                <div class="error">{{ error }}</div>
                <div v-if="isLoggedIn">
                    <button v-if="isLoggedIn" @click="logout" class="logout">ログアウト</button>
                    <!--<SubscriptionComponent />-->
                </div>
                <div v-else @click="change" class="guest">
                    <p class="guest__message">{{ message }}</p>
                    <p v-on:click="reset()" class="btn-primary">
                        <router-link to="/login" class="btn-primary__txt">会員登録/ログイン</router-link>
                    </p>
                </div>
            </div>
        </div>
    </nav>
</template>
  
<script>
import axios from 'axios'
import removeItem from '../auth/removeItem'
//import SubscriptionComponent from '../components/SubscriptionComponent.vue'


export default {
    data () {
        return {
            name: window.localStorage.getItem('name'),
            email: window.localStorage.getItem('uid'),
            error: null,
            message: "初めての方は会員登録！",
            plan: "Premium",
            imgsUrl: require('@/assets/ico-user.svg'),
            shouldShowLoginForm: false,

            params: {
                text: ''
            },
        }
    },

    components: {
    //    SubscriptionComponent
    },

    computed: {
        isLoggedIn() {
            return Boolean(this.email)
        }
    },

    methods: {
        async logout() {
            this.error = null;

            try {
                // 環境変数から API ベース URL を取得
                const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

                const res = await axios.delete(`${apiBaseUrl}/auth/sign_out`, {
                    headers: {
                        uid: this.email,
                        'access-token': window.localStorage.getItem('access-token'),
                        client: window.localStorage.getItem('client'),
                    },
                });

                if (!res) {
                    throw new Error('ログアウトできませんでした');
                }

                console.log('ログアウトしました');
                removeItem();
                window.location.reload();
                this.$router.push({ name: 'LogIn' });

                return res;
            } catch (error) {
                console.error(error);
                this.error = 'ログアウトできませんでした';
            }
        },
    },
}
</script>
  
  
<style scoped>
    .plan {
        width: 33%;
        padding: 2px 2px;
        /* margin: 0 auto 16px; */
        color: #fff;
        margin-bottom: 8px;
        font-size: 11px;
        border-radius: 4px;
        background: linear-gradient(150deg, rgba(84,170,249,1) 0%, rgba(155,84,249,1) 40%, rgba(197,93,253,1) 60%, rgba(252,83,184,1) 100%);    }
    nav {
      padding: 24px;
      border-bottom: 1px solid #eee;
    }
    .user-area {
      text-align: left;
      width: 100%;
    }
    .user-area__inner {
        width: 100%;
    }
    .user-area img {
        width: 40px;
        margin-right: 10px;
    }
    .user-area p {
      margin: 2px auto;
      font-size: 16px;
    }
    .user-area .name {
        font-weight: bold;
    }
    .user-area p.email {
      font-size: 13px;
      color: #999;
    }
    .guest {
        text-align: center;
        padding-top: 20px;
    }
    .logout {
        color: #AE50F8;
    }


</style>
  