<template>
  <div>
    <h2>ログイン</h2>
    <form @submit.prevent="login">
      <input type="email" required placeholder="メールアドレス" v-model="email">
      <input type="password" required placeholder="パスワード" v-model="password">
      <div class="error">{{ error }}</div>
      <button class="btn-primary"><span class="btn-primary__txt">ログイン</span></button>
    </form>
  </div>
</template>

  
<script>
  import axios from 'axios'
  import setItem from '../auth/setItem'

  export default {
    //emits: ['redirectToChatRoom'],

    data () {
      return {
        email: '',
        password: '',
        error: null
      }
    },
    methods: {
  async login() {
    try {
      this.error = null;

      // 環境変数からAPIのベースURLを取得
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

      // エンドポイントを環境変数から設定
      const res = await axios.post(`${apiBaseUrl}/auth/sign_in`, {
        email: this.email,
        password: this.password,
      },
      {
        withCredentials: true, // 認証情報を含める設定
      });

      if (!res) {
        throw new Error('メールアドレスかパスワードが違います');
      }

      if (!this.error) {
        setItem(res.headers, res.data.data.name);
        this.$router.push({ name: 'UserHome' });
      }

      console.log({ res });
      return res;
    } catch (error) {
      console.log({ error });
      this.error = 'メールアドレスかパスワードが違います';
    }
  },
}

  }
</script>
  